* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

.loader svg{
  height: 5rem;
  width: 5rem;
  margin: auto;
  animation: spin 2s infinite linear;
  animation-direction: reverse;
  color: #CCCCCC;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}