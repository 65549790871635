@import url('https://fonts.googleapis.com/css?family=Pinyon+Script');

:root {
  --inceptia-green: #006d3f;
  --link-color: #0277bd;
  --text-color: #424242;
  --error-color: #d32f2f;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
}

/* add a min height to the surveyjs block */
.sd-root-modern {
  min-height: 300px;
}

/*
  Override the default disabled opacity of 0.25 provided by SurveyJS for many of its controls.
  The "sd-root-modern" class is at the root level of the surveyjs block - adding it to the rules to provide enough specificity to override the default.
*/
.sd-root-modern .sv-button-group__item--disabled .sv-button-group__item-decorator,
.sd-root-modern .sd-ranking--disabled .sv-ranking-item__text,
.sd-root-modern .sv-ranking-item--disabled .sv-ranking-item__text,
.sd-root-modern .sd-element__title.sd-element__title--disabled,
.sd-root-modern .sd-input.sd-input--disabled, .sd-root-modern .sd-input.sd-input--disabled::placeholder,
.sd-root-modern .sd-question--disabled .sd-table__cell,
.sd-root-modern .sd-item--disabled .sd-item__control-label,
.sd-root-modern .sd-question--disabled .sd-rating__item-text,
.sd-root-modern .sd-boolean--disabled .sd-boolean__thumb, .sd-root-modern .sd-boolean--disabled .sd-boolean__label {
  /* opacity: .25 */
  opacity: 0.75;
}

/* Override the disabled color of the inner circle and checkmark of selected radio and checkbox inputs by changing the value of the CSS variable used. */
.sd-root-modern .sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after,
.sd-root-modern .sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  /* --sjs-border-default: rgba(0, 0, 0, 0.16); */
  --sjs-border-default: rgba(0, 0, 0, 0.35);
}

/* Overrides to fix Notistack's snackbar accessibility contrast errors */
.notistack-MuiContent-success {
  background-color: #347c37 !important;
}
.notistack-MuiContent-warning {
  background-color: #ffad33 !important;
  color: #000 !important;
}
.notistack-MuiContent-warning .MuiButtonBase-root {
  color: #000 !important;
}
.notistack-MuiContent-info {
  background-color: #0b73c5 !important;
}
